
import Vue from "vue";
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: "Snackbar",
    computed: {
      ...mapGetters({
        'snackbar': 'getSnackbarInstance',
      }),
    },
    methods: {
      customColor(color: string): string {
        if(color === 'success') {
          return "#8BC34A"
        } else {
          return color
        }
      }
    }
});
